import React from "react"
import Background from './../components/background'
import './../css/global.css'
import Logo from './../assets/logo.svg';
import smile from './../components/smiley.gif'
import Signup from './../components/signup.js'
require('typeface-courier-prime')

const IndexPage = () => {
  return (
    <div style = {{maxWidth:`100vw`, height:`100vh`,overflow:`hidden`, justifyContent:`center`, display:`flex`}}>

    <div style = {{position:`fixed`, zIndex:`-100`}}>
    <Background/>
    </div>

    <div style = {{position:`fixed`, width:`100%`, display:`flex`, justifyContent:`center`}}>
    <img src={smile} style ={{marginLeft: `20vw`, height:`30vh`}} />
    </div>

    <div style = {{position:`fixed`, width:`100%`, display:`flex`, justifyContent:`center`}}>
    <Logo style = {{height:`80vh`, margin:`auto`}}/>
    </div>

    <div className = 'textHolder' style = {{maxWidth:`500px`, margin:`auto`, paddingLeft:`20px`, paddingRight:`20px`, position:`fixed`, bottom:`10vh`, textAlign:`center`, fontFamily:`Courier`}}>
    <b>Big</b> things are about to come. Hit us up with that <b>~~email~~.</b>
    <br/>
    <br/>
    <Signup/>
    </div>
    </div>
  )
}




export default IndexPage
